.header_1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 5px;
  color: var(--dimBlack);
}

.header_1 > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.header_1 > div > span {
  font-weight: 500;
  color: var(--dimBlack);
}

.border {
  border-bottom: 2px solid #eee;
}

.free {
  background: var(--primaryColor);
  color: #fff !important;
  padding: 3px 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

.body_text,
.check_box {
  padding: 0.3rem;
}

.body_text p {
  font-weight: 500;
  color: #474747;
}

.check_box {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
}

.check_box div {
  box-shadow: 1px 5px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.check_box div span {
  font-size: 14px;
  color: var(--dimBlack);
  font-weight: 500;
}

.box_footer {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
}

.left_arrow {
  background: #bfbfbf;
  color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
}

.right_arrow {
  background: var(--primaryColor);
  color: #fff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  cursor: pointer;
}

.submit_btn {
  outline: none;
  border: none;
  background: var(--primaryColor);
  color: #fff;
  padding: 12px 35px;
  border-radius: 2rem;
  -webkit-border-radius: 2rem;
  -moz-border-radius: 2rem;
  -ms-border-radius: 2rem;
  -o-border-radius: 2rem;
  cursor: pointer;
  font-weight: 500;
}

.submit_btn:hover {
  background: #ff4e54;
}
