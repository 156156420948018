.heroSection {
  margin-top: 5rem;
  background: var(--primaryColor);
  height: 75vh;
}

.heroSection img {
  margin-top: 3rem;
  width: 85%;
  margin-left: 7rem;
}
.options {
  background: #ffeef0;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  margin-top: 3rem;
  height: 23vh;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.left_side {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 25%;
}

.left_side p {
  background: var(--primaryColor);
  padding: 0.7rem 0.9rem;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
}

.right_side {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  margin-left: 1.5rem;
}

.right_side div {
  width: 130px;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  background: #fff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  cursor: pointer;
}

.m_icon {
  font-size: 45px !important;
  color: var(--dimBlack);
}

.right_side div span {
  text-align: center;
  font-size: 13px;
  color: rgba(51, 51, 51, 1);
}

.box_section {
  height: 100vh;
  width: 100%;
  margin-top: 13rem;
}

.boxes {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.box1,
.box2,
.box3 {
  border: 2px solid #eee;
  border-radius: 0.7rem;
  -webkit-border-radius: 0.7rem;
  -moz-border-radius: 0.7rem;
  -ms-border-radius: 0.7rem;
  -o-border-radius: 0.7rem;
  padding: 15px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 500px;
}

.expore {
  margin-top: -9rem;
}

.our_facilities {
  height: 450px;
  background: var(--primaryColor);
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.facility_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.falcility {
  background: #fff;
  height: 150px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 1.5rem;
}

.left_sec {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left_sec > h1 {
  color: var(--dimBlack);
}

.explore_now_btn {
  border: 2px solid var(--primaryColor);
  color: var(--primaryColor);
  border-radius: 5px;
  -webkit-border-radius: ;
  -moz-border-radius: ;
  -ms-border-radius: ;
  -o-border-radius: ;
  background: transparent;
  padding: 10px 10px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

.founder_details {
  margin-top: 4rem;
  margin-bottom: 2rem;
}
