.header_3 {
  display: flex;
  gap: 1rem;
  color: var(--dimBlack);
}

.contents {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  padding: 0px 10px;
}

.contents > p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 10px;
  font-size: 14px;
  gap: 3rem;
  font-weight: 500;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
  color: var(--dimBlack);
}
.full_date {
  width: 50%;
  color: #646f79;
  font-size: 12px;
}
