.founder_title {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  text-align: center;
}

.founder_title > h1 {
  font-size: 2.5rem;
}

.founder_title > p {
  font-size: 1.5rem;
}

.founder_box_wrapper {
  margin-top: 4rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
}

.founder_left {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 238, 240, 1);
  padding: 10px 30px;
  height: 700px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.founder_left > img {
  width: 13rem;
}

.founder_left > h1 {
  color: var(--primaryColor);
  margin-top: 0.5rem;
  font-size: 1.8rem;
}
.founder_left > span {
  color: var(--primaryColor);
  font-weight: 600;
  font-size: 1rem;
}
.founder_left > h2 {
  color: var(--dimBlack);
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-size: 1.3rem;
}

.founder_left > p {
  color: var(--dimBlack);
  text-align: justify;
  font-size: 18px;
  line-height: 30px;
  margin-top: 0.8rem;
}

.founder_right {
  width: 100%;
  height: 700px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background: var(--primaryColor);
  color: #fff;
}

.founder_right > img {
  width: 100%;
  margin-top: 1rem;
}

.founder_right > h1 {
  font-size: 2.2rem;
  margin-top: 4rem;
  text-align: center;
  padding: 0px 10px;
}
