.header_2 {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--dimBlack);
}

.header_2 > p {
  font-weight: 500;
}

.content_wrapper {
  overflow: scroll;
  padding: 0px 10px;
}

.content_heading {
  padding: 0.5rem 0rem;
  font-size: 1rem;
  box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-top: 0.5rem;
}

.main_contents {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 0.5rem;
  width: 100%;
}

.main_content_box {
  display: flex;
  flex-direction: column;
  gap: 6px;
  box-shadow: 1px 4px 10px 1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  padding: 8px 10px;
}

.main_content_box > p {
  font-size: 15px;
  margin-left: 0.5rem;
}

.main_content_box_header {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #eee;
}

.main_content_box_header p {
  display: flex;
  align-items: center;
  font-size: 12px;
  gap: 8px;
}

.content_title {
  color: var(--primaryColor);
}

.date {
  color: var(--lightGrey);
}
