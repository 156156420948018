.facility_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
}

.falcility {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  height: 150px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 1.5rem;
}

.left_sec {
  height: 100%;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.left_sec > h1 {
  color: var(--dimBlack);
}

.explore_now_btn {
  border: 2px solid var(--primaryColor);
  color: var(--primaryColor);
  border-radius: 5px;
  -webkit-border-radius: ;
  -moz-border-radius: ;
  -ms-border-radius: ;
  -o-border-radius: ;
  background: transparent;
  padding: 12px;
  font-weight: 500;
  width: 9rem;
  font-size: 14px;
  cursor: pointer;
}

.right_sec {
  height: 100%;
  width: 30%;
  padding: 20px;
  background: #e9efff;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}

.right_sec > img {
  width: 4rem;
}

.explore_more {
  background: rgba(255, 255, 255, 1);
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--primaryColor);
  margin-top: 2.2rem;
}
