nav {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0px 5rem;
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);
}

nav .title {
  font-size: 0.9rem;
  text-decoration: none;
  font-weight: 500;
  color: #000;
}

nav ul {
  display: flex;
}

nav ul li {
  list-style: none;
}
nav ul li a {
  display: block;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 3px;
  transition: 0.3s;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

nav ul li a:not(.active):hover {
  background: #ffeef0;
}

.logo img {
  width: 5rem;
  cursor: pointer;
}

.active {
  color: var(--primaryColor) !important;
}

.right_section {
  display: flex;
  gap: 1rem;
}

.search {
  width: 50px;
  height: 50px;
  border: 1px solid #d5d5d5;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile_avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  border: 1px solid var(--primaryColor);
  padding: 10px 10px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
}

.hamburger {
  display: none;
  position: absolute;
  top: 1rem;
  right: 1rem;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
}

@media (max-width: 1000px) {
  .search,
  .profile_avatar {
    display: none;
  }
}

@media (max-width: 800px) {
  .hamburger {
    display: flex;
  }
  .logo img {
    width: 4rem;
  }

  nav {
    flex-direction: column;
    align-items: start;
  }

  nav ul {
    display: none;
    flex-direction: column;
    width: 100%;
    margin-bottom: 0.3rem;
    margin-top: 1rem;
  }

  nav ul li {
    width: 100%;
    text-align: center;
  }

  nav ul.open {
    display: flex;
  }
}
